import { $ } from "./express.js";
import Sticky from "./tow-and-go/sticky.js";

new Sticky(".page-header", {
  onchange: function(isStuck, sticky) {
    $(sticky).toggleClass("stuck", isStuck);
  }
});

// Prevents clicking to close, as the the panel would be hidden,
// giving us nothing to transition.
$("body").on("click", "details summary", function(event) {
  const $details = $(this).closest("details");
  const details = $details[0];

  event.preventDefault();

  if (!details.open) {
    // Always open the details so we can animate.
    details.open = true;
  }
});

$("body").on("click", `button[menu]`, function(event) {
  const $button = $(this);
  const targetId = $button.attr("menu");
  const $target = $(`#${targetId}`);
  const open = $target.hasClass("active");

  $button.attr("aria-expaned", !open);
  $target.toggleClass("active", !open);
});

$(document).on("click", function(event) {
  const $target = $(event.target);
  let $menu = $target.closest(".menu.active, button[menu]");

  if (!$menu.length) {
    $menu = $(".menu.active");
    if ($menu.length) {
      const id = $menu.attr("id");
      $(`button[menu="${id}"]`).attr("aria-expanded", false);
      $menu.removeClass("active");
    }
  }
});

$(".page-content form").each(function() {
  const $form = $(this);

  $form.find(`input[type="email"]`).each(function() {
    const $email = $(this);
    const name = $email.attr("name");

    if (name.toLowerCase().indexOf("confirmation") === -1) {
      const $confirmation = $form.find(`input[name="${name} Confirmation"]`);
      const validate = () => validateMatch($email[0], $confirmation[0]);

      $email.on("change", validate);
      $confirmation.on("keyup", validate);
    }
  });
});

$("form.ajax").on("submit", function(event) {
  event.preventDefault();
  var $form = $(this);

  const $submit = $form.find(`[type="submit"]`);
  $submit.attr("disabled", "disabled");
  $submit.text("Please wait...");

  const xhr = new XMLHttpRequest();

  xhr.open($form.attr("method"), $form.attr("action") || "");
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  xhr.onload = function() {
    if (xhr.status === 200) {

    }
    else if (xhr.status !== 200) {
      console.error("Request failed.  Returned status of " + xhr.status);
    }

    $form.replaceWith("<p class=\"success\">Thank you! Your submission has been received!</p>");
  };
  xhr.send($form.serialize());
});

function validateMatch(inputA, inputB){
  if (!inputB) return;

  const match = inputA.value === inputB.value;
  if(!match) {
    inputB.setCustomValidity("Please enter the same value.");
  } else {
    inputB.setCustomValidity("");
  }
  $(inputB).toggleClass("error", !match);
}

$(".page-content form [name]").on("change invalid", function(event) {
  const input = event.target;
  $(input).toggleClass("error", !input.validity.valid);
});

$(".contact.form").each(function() {
  const $form = $(this);
  const $toggles = $form.find(`input[name="Preferred Contact Method"]`);
  const $row = $toggles.closest(".col-12");
  const $others = $row.siblings();

  let chosen = false;

  $others.addClass("collapsed").css({
    "height": 0,
    "padding-top": 0,
    "padding-bottom": 0
  });

  let $inputs = $();

  $toggles.each(function() {
    const $toggle = $(this);
    const value = $toggle.val();
    $inputs = $inputs.add($form.find(`[name*="${value}"]`));
  });

  $toggles.on("change", function() {
    if (!chosen) {
      $others.removeClass("collapsed").css({
        "height": "",
        "padding-top": "",
        "padding-bottom": ""
      });
    }

    const value = this.value;

    $inputs.attr("required", null).addClass("optional");
    $inputs.filter(`[name*="${value}"]`)
    .attr("required", "required")
    .removeClass("optional");

    if (chosen) {
      $inputs.trigger("change");
    }

    chosen = true;
  });
});
