/**
 * Dispatches a `sticky-event` custom event on the element.
 * @param {boolean} stuck
 * @param {!Element} target Target element of event.
 */
function fire(stuck, target) {
  const evt = new CustomEvent("sticky-change", {detail: {stuck, target}});
  document.dispatchEvent(evt);
}

function createObserver(selector, callback = fire) {
  const observer = new IntersectionObserver((records, observer) => {
    records.forEach(function(record) {
      const {target, boundingClientRect, rootBounds} = record;
      const stickyTarget = target.parentElement.querySelector(selector);

      if (boundingClientRect.bottom < rootBounds.top) {
        callback(true, stickyTarget);
      }
      if (boundingClientRect.bottom >= rootBounds.top &&
          boundingClientRect.bottom < rootBounds.bottom) {
       callback(false, stickyTarget);
      }
    });
  }, {
    threshold: [0],
    root: null
  });

  return observer;
}

export default class Sticky {
  constructor(selector, options = {}) {
    if (typeof options === "function") {
      options = {onchange: options};
    }

    const observer = createObserver(selector, options.onchange);

    const stickies = document.querySelectorAll(selector);
    let count = stickies.length;

    while (count--) {
      const sticky = stickies[count];
      const sentinel = document.createElement("div");
      sentinel.classList.add("sentinel", "sentinel--top");
      sticky.parentElement.appendChild(sentinel);
      observer.observe(sentinel);
    }
  }
}
