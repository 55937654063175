const supportsCustomProperty =  window.CSS && window.CSS.supports && window.CSS.supports('(--a: 0)');

const defaults = {
  slideSelector: ".carousel__item",
  viewportSelector: ".carousel__viewport",
  nextSelector: ".carousel__control--next",
  prevSelector: ".carousel__control--prev",
  indicatorSelector: ".carousel__indicators li a"
};

/**
 * A simple carousel implementation for demo purpose.
 *
 * @export
 * @class Carousel
 * @extends {Array}
 *
 * @example
 *
 *     new Carousel(el, {
 *       slideSelector: ".carousel__item",
 *       viewportSelector: ".carousel__viewport",
 *       nextSelector: ".carousel__control--next",
 *       prevSelector: ".carousel__control--prev",
 *       indicatorSelector: ".carousel__indicators li a"
 *     });
 */
export default class Carousel extends Array {

  /**
   * Creates an instance of Carousel.
   * @param {Node} el
   * @param {object} [options={}]
   * @memberof Carousel
   */
  constructor(el, options = {}) {
    options = Object.assign({}, defaults, options);
    const {
      slideSelector,
      viewportSelector,
      nextSelector,
      prevSelector,
      indicatorSelector
    } = options;

    const slides = el.querySelectorAll(slideSelector);
    // Spread the items as arguments to Array.constructor.
    super(...slides);

    this.el = el;
    this.viewport = el.querySelector(viewportSelector);
    const indicators = this.indicators = el.querySelectorAll(indicatorSelector);
    this._index = 0;
    this.options = options;

    el.addEventListener("click", (event) => {
      const target = event.target;

      if (target.matches(prevSelector)) {
        event.preventDefault();
        this.index--;
      }

      if (target.matches(nextSelector)) {
        event.preventDefault();
        this.index++;
      }

      if (target.matches(indicatorSelector)) {
        let indicatorCount = indicators.length;
        while (indicatorCount--) {
          if (indicators[indicatorCount] === target) {
            this.index = indicatorCount;
          }
        }
      }
    });
  }

  /**
   * Returns the current index.
   *
   * @memberof Carousel
   */
  get index() {
    return this._index;
  }

  /**
   * Moves the carousel to a specific index.
   *
   * @memberof Carousel
   */
  set index(value) {
    const {length: count, el, viewport, options} = this;

    if (!count) {
      value = 0;
    } else {
      value = value % count;
      if (value < 0) {
        value = count - 1;
      }
    }

    this._index = value;

    if (supportsCustomProperty) {
      el.style.setProperty("--index", value);
    } else {
      viewport.style.transform = "translateX(" + (-100 * value) + "%)";
    }

    const activeIndicator = el.querySelector(".carousel__indicators .active");
    activeIndicator.classList.remove("active");

    const indicators = el.querySelectorAll(options.indicatorSelector);
    indicators[value].classList.add("active");
  }
}
