import $ from "cash-dom";

/*
Parses YouTube URLs directly or from iframe code.  Handles:
* Address bar on YouTube url (ex: https://www.youtube.com/watch?v=ZFqlHhCNBOI)
* Direct http://youtu.be/ url (ex: https://youtu.be/ZFqlHhCNBOI)
* Full iframe embed code (ex: <iframe src="https://www.youtube.com/embed/ZFqlHhCNBOI">)
* Old <object> tag embed code (ex: <object><param name="movie" value="https://www.youtube.com/v/ZFqlHhCNBOI">...)
*/
const regex = /(www\.youtube\.com|www\.youtube-nocookie\.com|youtu\.be)\/(?:embed\/|v\/|watch\?v=)?(.{11})(?:.+)?/;

const $document = $(document);
const $lightbox = $("dialog.lightbox");
const $container = $lightbox.find(".lightbox__container");

function stopVideo(event) {
  const $dialog = $(event.target);
  const $video = $dialog.find("video");
  if ($video.length) {
    const video = $video[0];
    video.pause();
    video.currentTime = 0;
  }

  $dialog.find("iframe").remove();
}

$document.on("click", `a[href*="youtu"]`, function(event) {
  event.preventDefault();

  const $trigger = $(this);
  const url = $trigger.attr("href");
  const rel = $trigger.attr("rel");
  const $thumbnail = $trigger.find("img");
  const [_, hostname, id] = url.match(regex) || [];

  if (hostname === "youtu.be") {
    hostname = "www.youtube.com";
  }

  const $iframe = $(`<iframe frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>`);
  $iframe.attr("src", `https://${hostname}/embed/${id}?autoplay=1&rel=0&showinfo=0&modestbranding=1`);
  $iframe.attr("width", $thumbnail.width() || $trigger.width());
  $iframe.attr("height", $thumbnail.height() || $trigger.height());

  if (rel.indexOf("lightbox") > -1) {
    $container.append($iframe);
    $lightbox[0].showModal();
  } else {
    $thumbnail.replaceWith($iframe);
    $trigger.addClass("transformed");
  }
});

$lightbox
  .on("cancel", stopVideo)
  .on("close", stopVideo)
  .on("click", function(event) {
    const dialog = event.target;
    const rect = dialog.getBoundingClientRect();
    const isInDialog=(rect.top <= event.clientY && event.clientY <= rect.top + rect.height
      && rect.left <= event.clientX && event.clientX <= rect.left + rect.width);
    if (!isInDialog) {
      dialog.close();
    }
  })
  .on("click", ".close", function(event) {
    $(event.target).closest("dialog")[0].close();
  });
