import * as _svguse from "svgxuse";
import _picturefill from "picturefill";
import objectFitImages from 'object-fit-images';
import dialogPolyfill from "dialog-polyfill";
import $ from "cash-dom";
import Carousel from "./express/carousel.js";
import * as _accordion from "./express/accordion.js";
import * as _lightbox from "./express/lightbox.js";

const dialogs = document.querySelectorAll("dialog");
let count = dialogs.length;

while (count--) {
  dialogPolyfill.registerDialog(dialogs[count]);
}

const coverImages = document.querySelectorAll("img.cover");
objectFitImages(coverImages);

const carousels = document.getElementsByClassName("carousel");
count = carousels.length;

while(count--) {
  new Carousel(carousels[count], {
    slides: ".carousel-item"
  });
}

export { default as $ } from "cash-dom";
export { default as Carousel } from "./express/carousel.js";
