import $ from "cash-dom";

$("body").on("click", "details summary", function(event) {
  const $summary = $(this);
  const $details = $summary.closest("details");
  const $panel = $details.find(".accordion-panel");

  const open = $details.hasClass("active");
  $summary.attr("aria-expanded", !open);
  $panel.attr("aria-hidden", open);

  setTimeout(() => {
    $details.toggleClass("active");
  });
});
